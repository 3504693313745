import { MediaBreakpoint, Skeleton, spacing, useResponsive } from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 40rem;

  ${MediaBreakpoint.PHONE} {
    width: ${spacing(11)};
  }
`;

const Column = styled.div`
  flex: 1 1 50%;
  margin-right: ${spacing(7)};

  ${MediaBreakpoint.PHONE} {
    margin: ${spacing(2)} 0 0;
  }
`;

const StyledSkeleton = styled.div`
  opacity: 0.5;
`;

export const ApplicationHeaderSkeleton = (_props: { backgroundColor?: string }) => {
  const responsive = useResponsive();

  const skeletonEl = (
    <StyledSkeleton>
      <Skeleton.Line lineHeight={10} />
      <Skeleton.Line lineHeight={10} width="75%" />
    </StyledSkeleton>
  );

  return (
    <Container>
      <Column>{skeletonEl}</Column>
      {responsive.PHONE.BIGGER ? <Column>{skeletonEl}</Column> : null}
    </Container>
  );
};
