import { Skeleton } from '@mortgagehippo/ds';
import { useApplicationFileApplicants } from '@mortgagehippo/tasks';
import { defer } from '@mortgagehippo/util';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import {
  ActionStatus,
  ActionType,
  type IUploadDocumentsAction,
  type IUploadLenderDocumentsAction,
  useActionEffect,
  useDispatchAction,
} from '$components/actions';
import { useApplicationFileCan } from '$components/permissions';

import { DocumentType, type LosType } from '../../../apollo/graphql';
import { isSuccessfulPush, PUSH_DOCUMENTS_INDIVIDUALLY } from '../application-services';
import { useLosPush } from '../application-services/use-los-push';
import { useApplicationFileDocuments } from '../use-application-file-documents';
import { useCreateDocumentsArchive } from '../use-create-documents-archive';
import { ApplicationDocumentsContent } from './application-documents-content';
import { useApplicationFileLosTypes } from './use-application-file-los-types';
import { useDeleteLenderDocument } from './use-delete-lender-document';

interface IApplicationDocumentsContentContainerProps {
  applicationFileId: string;
}

export const ApplicationDocumentsContentContainer = (
  props: IApplicationDocumentsContentContainerProps
) => {
  const { applicationFileId } = props;

  const [selectedLosType, setSelectedLosType] = useState<LosType | null | undefined>(undefined);

  const dispatch = useDispatchAction();

  const [can, canReady] = useApplicationFileCan(applicationFileId);

  const [losTypes, losTypesLoading] = useApplicationFileLosTypes(applicationFileId);

  const skipDocuments = losTypesLoading || selectedLosType === undefined;
  const [documents, documentsLoading, refreshDocuments] = useApplicationFileDocuments(
    applicationFileId,
    [DocumentType.LenderDocument, DocumentType.SentDocument, DocumentType.SubmittedDocument],
    selectedLosType,
    { skip: skipDocuments }
  );

  const [{ data: applicants, loading: applicantsLoading }] =
    useApplicationFileApplicants(applicationFileId);

  const createDocumentsArchive = useCreateDocumentsArchive();
  const deleteDocument = useDeleteLenderDocument();

  const [losPush, isLosPushLoading] = useLosPush(applicationFileId, selectedLosType!, {
    skip: isNil(selectedLosType),
  });

  const isLosApplicationPushed = !!losPush && isSuccessfulPush(losPush);

  const handleUploadDocument = useCallback(() => {
    dispatch({
      type: ActionType.UPLOAD_DOCUMENTS,
      applicationFileId,
    });
  }, [applicationFileId, dispatch]);

  const handleDeleteLenderDocument = useCallback(
    async (documentId: string) => {
      await deleteDocument(applicationFileId, documentId);
      await refreshDocuments();
    },
    [applicationFileId, deleteDocument, refreshDocuments]
  );

  const handleCreateDocumentsArchive = useCallback(
    async (documentIds: string[]) => {
      if (!documentIds.length) {
        return;
      }

      await createDocumentsArchive(applicationFileId, documentIds);
    },
    [applicationFileId, createDocumentsArchive]
  );

  const handleLosDocumentPush = useCallback(
    (documentId: string) => {
      const { promise, resolve } = defer();

      if (!selectedLosType) {
        return undefined;
      }

      dispatch(
        {
          type: ActionType.PUSH_DOCUMENT_TO_LOS,
          applicationFileId,
          documentId,
          losType: selectedLosType,
        },
        async (_action, status, result) => {
          if (status === ActionStatus.DONE) {
            await refreshDocuments();
          }
          resolve(result);
        }
      );

      return promise;
    },
    [applicationFileId, dispatch, refreshDocuments, selectedLosType]
  );

  const handleLosDocumentsPush = useCallback(
    async (documentIds: string[]) => {
      if (PUSH_DOCUMENTS_INDIVIDUALLY) {
        return Promise.all(documentIds.map((docId) => handleLosDocumentPush(docId)));
      }

      const { promise, resolve } = defer();
      if (!selectedLosType) {
        return undefined;
      }

      dispatch(
        {
          type: ActionType.PUSH_DOCUMENTS_TO_LOS,
          applicationFileId,
          losType: selectedLosType,
          documentIds,
        },
        async (_action, status, result) => {
          if (status === ActionStatus.DONE) {
            await refreshDocuments();
          }

          resolve(result);
        }
      );

      return promise;
    },
    [applicationFileId, dispatch, handleLosDocumentPush, refreshDocuments, selectedLosType]
  );

  useActionEffect<IUploadDocumentsAction | IUploadLenderDocumentsAction>(
    async (action) => {
      const { applicationFileId: actionApplicationFileId, type } = action;

      if (
        actionApplicationFileId !== applicationFileId ||
        (type !== ActionType.UPLOAD_DOCUMENTS && type !== ActionType.UPLOAD_LENDER_DOCUMENTS)
      ) {
        return;
      }

      await refreshDocuments();
    },
    undefined,
    ActionStatus.DONE
  );

  useEffect(() => {
    if (losTypesLoading) {
      return;
    }

    setSelectedLosType(losTypes[0] || null);
  }, [losTypes, losTypesLoading]);

  const loading =
    losTypesLoading || documentsLoading || applicantsLoading || !canReady || isLosPushLoading;

  if (loading) {
    return <Skeleton.Paragraph lineCount={6} />;
  }

  return (
    <ApplicationDocumentsContent
      applicationFileId={applicationFileId}
      documents={documents}
      applicants={applicants}
      losTypes={losTypes}
      activeLosType={selectedLosType}
      showPushApplicationAlert={!isLosApplicationPushed && !isNil(selectedLosType)}
      onChangeLosType={setSelectedLosType}
      onUploadDocument={can.UPLOAD_DOCUMENT ? handleUploadDocument : undefined}
      onDeleteLenderDocument={can.DELETE_LENDER_DOCUMENT ? handleDeleteLenderDocument : undefined}
      onCreateDocumentsArchive={can.EXPORT_DOCUMENTS ? handleCreateDocumentsArchive : undefined}
      preventDownloads={!can.DOWNLOAD_DOCUMENTS}
      onTriggerDocumentsPush={can.PUSH_DOCUMENT_TO_LOS ? handleLosDocumentsPush : undefined}
    />
  );
};
