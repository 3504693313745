import { Skeleton } from '@mortgagehippo/ds';
import { UnreachableCaseError } from '@mortgagehippo/util';
import { type ComponentType, lazy, type ReactElement, Suspense } from 'react';

import { type IApplicationApplicant } from '../use-application-file-data';
import { type IApplicationServicePageProps, ServiceType } from './types';

const CreditCheckPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/credit-check').then((module) => ({ default: module.CreditCheckPageContainer }))
);

const BytePageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/los/byte').then((module) => ({ default: module.BytePageContainer }))
);

const EncompassPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/los/encompass').then((module) => ({ default: module.EncompassPageContainer }))
);

const AusPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/aus').then((module) => ({ default: module.AusPageContainer }))
);

const MortgageBotPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/los/mortgagebot').then((module) => ({
    default: module.MortgageBotPageContainer,
  }))
);

const MortgageflexPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/los/mortgageflex').then((module) => ({
    default: module.MortgageflexPageContainer,
  }))
);

const MortgageInsurancePageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/mortgage-insurance').then((module) => ({
    default: module.MortgageInsurancePageContainer,
  }))
);

const IDSPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/ids').then((module) => ({ default: module.IDSPageContainer }))
);

const InternalSystemPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/internal-system').then((module) => ({
    default: module.InternalSystemPage,
  }))
);

const WorkNumberPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/work-number').then((module) => ({ default: module.WorkNumberPage }))
);

const FinicityPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/finicity').then((module) => ({ default: module.FinicityPage }))
);

const AccountChekPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/account-chek').then((module) => ({ default: module.AccountChekPage }))
);

const MortgageDirectorPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/los/mortgage-director').then((module) => ({
    default: module.MortgageDirectorPageContainer,
  }))
);

const LendingQbPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/los/lending-qb').then((module) => ({ default: module.LendingQbPageContainer }))
);

const SmartFeesPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/smart-fees').then((module) => ({ default: module.SmartFeesPage }))
);

const DocutechPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/docutech').then((module) => ({ default: module.DocutechPage }))
);

const TotalExpertPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/total-expert').then((module) => ({ default: module.TotalExpertPage }))
);

const VelocifyPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/velocify').then((module) => ({ default: module.VelocifyPage }))
);

const TrueworkContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/truework').then((module) => ({ default: module.TrueworkPage }))
);

const UnderwritingConditionsPageContainer: ComponentType<IApplicationServicePageProps> = lazy(() =>
  import('./services/underwriting-conditions').then((module) => ({
    default: module.UnderwritingConditionsPageContainer,
  }))
);

interface IApplicationServicesContentProps {
  applicationFileId: string;
  applicants: IApplicationApplicant[];
  type: ServiceType;
}

export const ApplicationServicesContent = (props: IApplicationServicesContentProps) => {
  const { applicationFileId, applicants, type } = props;

  let el: ReactElement<IApplicationServicePageProps> | null;
  switch (type) {
    case ServiceType.CreditPull: {
      el = (
        <CreditCheckPageContainer applicationFileId={applicationFileId} applicants={applicants} />
      );
      break;
    }
    case ServiceType.Byte: {
      el = <BytePageContainer applicationFileId={applicationFileId} applicants={applicants} />;
      break;
    }
    case ServiceType.EncompassApi: {
      el = <EncompassPageContainer applicationFileId={applicationFileId} applicants={applicants} />;
      break;
    }
    case ServiceType.AUS: {
      el = <AusPageContainer applicationFileId={applicationFileId} applicants={applicants} />;
      break;
    }
    case ServiceType.MortgageBot: {
      el = (
        <MortgageBotPageContainer applicationFileId={applicationFileId} applicants={applicants} />
      );
      break;
    }
    case ServiceType.MortgageFlex: {
      el = (
        <MortgageflexPageContainer applicationFileId={applicationFileId} applicants={applicants} />
      );
      break;
    }
    case ServiceType.MortgageInsurance: {
      el = (
        <MortgageInsurancePageContainer applicationFileId={applicationFileId} applicants={applicants} />
      );
      break;
    }
    case ServiceType.IDS: {
      el = <IDSPageContainer applicationFileId={applicationFileId} applicants={applicants} />;
      break;
    }
    case ServiceType.InternalSystem: {
      el = (
        <InternalSystemPageContainer
          applicationFileId={applicationFileId}
          applicants={applicants}
        />
      );
      break;
    }
    case ServiceType.WorkNumber: {
      el = (
        <WorkNumberPageContainer applicationFileId={applicationFileId} applicants={applicants} />
      );
      break;
    }
    case ServiceType.Finicity: {
      el = <FinicityPageContainer applicationFileId={applicationFileId} applicants={applicants} />;
      break;
    }
    case ServiceType.AccountChek: {
      el = (
        <AccountChekPageContainer applicationFileId={applicationFileId} applicants={applicants} />
      );
      break;
    }
    case ServiceType.TotalExpert: {
      el = (
        <TotalExpertPageContainer applicationFileId={applicationFileId} applicants={applicants} />
      );
      break;
    }
    case ServiceType.Velocify: {
      el = <VelocifyPageContainer applicationFileId={applicationFileId} applicants={applicants} />;
      break;
    }
    case ServiceType.MortgageDirector: {
      el = (
        <MortgageDirectorPageContainer
          applicationFileId={applicationFileId}
          applicants={applicants}
        />
      );
      break;
    }
    case ServiceType.LendingQb: {
      el = <LendingQbPageContainer applicationFileId={applicationFileId} applicants={applicants} />;
      break;
    }
    case ServiceType.SmartFees: {
      el = <SmartFeesPageContainer applicationFileId={applicationFileId} applicants={applicants} />;
      break;
    }
    case ServiceType.Docutech: {
      el = <DocutechPageContainer applicationFileId={applicationFileId} applicants={applicants} />;
      break;
    }
    case ServiceType.Truework: {
      el = <TrueworkContainer applicationFileId={applicationFileId} applicants={applicants} />;
      break;
    }
    case ServiceType.UnderwritingConditions: {
      el = (
        <UnderwritingConditionsPageContainer
          applicationFileId={applicationFileId}
          applicants={applicants}
        />
      );
      break;
    }
    default: {
      throw new UnreachableCaseError(type);
    }
  }

  return <Suspense fallback={<Skeleton.Paragraph />}>{el}</Suspense>;
};
