import {
  type ISelectOption,
  Select,
  type UploadHandler,
  UploadInput,
  useFormValue,
} from '@mortgagehippo/ds';
import { ACCEPTED_DOCUMENT_MIME_TYPES, type IBaseTask, TaskType } from '@mortgagehippo/tasks';
import { useEffect, useMemo } from 'react';

import type { IApplicationApplicant } from '../../../pages/application/use-application-file-data';

export const LENDER_DOCUMENT_TOKEN = '__LENDER__';
const ALL_BORROWERS_TOKEN = '__COMMON__';

const isCommonSubmissionTask = (t: IBaseTask) =>
  t.typeName === TaskType.DocumentSubmissionTask && t.common;

const isBorrowerSpecificSubmissionTask = (t: IBaseTask, applicantId: string) =>
  t.typeName === TaskType.DocumentSubmissionTask &&
  `${t.primaryApplicantId}` === applicantId &&
  !t.common;

export interface IUploadDocumentsModalFormProps {
  applicants: IApplicationApplicant[];
  tasks: IBaseTask[];
  onUpload: UploadHandler;
}

export const UploadDocumentsModalForm = (props: IUploadDocumentsModalFormProps) => {
  const { applicants, tasks, onUpload } = props;
  const [applicantId] = useFormValue<string | undefined>('applicant_id');
  const [, setTaskId] = useFormValue<string | undefined>('task_id');
  const isMultiBorrower = applicants.length > 1;

  const isLenderDocument = applicantId === LENDER_DOCUMENT_TOKEN;
  const isCommonDocument = applicantId === ALL_BORROWERS_TOKEN;
  const showRelatedTask = !!applicantId && !isLenderDocument;

  const applicantOptions: ISelectOption[] = useMemo(() => {
    const o: ISelectOption[] = [
      {
        value: LENDER_DOCUMENT_TOKEN,
        label: 'None (not visible to borrowers)',
      },
    ];

    /*
     * Only show "All Borrowers" verbiage when there's more than 1 borrower. Common tasks will still display
     * on single borrower apps.
     */
    const hasCommonTasks = tasks.some((t) => isCommonSubmissionTask(t));
    if (isMultiBorrower && hasCommonTasks) {
      o.push({
        value: ALL_BORROWERS_TOKEN,
        label: 'All Borrowers',
      });
    }

    applicants.forEach((applicant) => {
      o.push({
        value: applicant.id,
        label: applicant.name,
      });
    });

    return o;
  }, [applicants, isMultiBorrower, tasks]);

  const taskOptions: ISelectOption[] = useMemo(() => {
    if (!applicantId || isLenderDocument) {
      return [];
    }

    const filteredTasks = tasks.filter((t) => {
      if (isCommonDocument) {
        return isCommonSubmissionTask(t);
      }

      if (isMultiBorrower) {
        return isBorrowerSpecificSubmissionTask(t, applicantId);
      }

      // if its only 1 borrower show both common and borrower specific tasks
      return isCommonSubmissionTask(t) || isBorrowerSpecificSubmissionTask(t, applicantId);
    });

    return filteredTasks.map((t) => ({
      value: t.id,
      label: t.meta.title,
    }));
  }, [applicantId, isCommonDocument, isLenderDocument, isMultiBorrower, tasks]);

  useEffect(() => {
    setTaskId(undefined);
  }, [applicantId, setTaskId]);

  return (
    <>
      <Select.Box
        name="applicant_id"
        placeholder="Select one"
        label="Borrower"
        options={applicantOptions}
        required
        initialValue={LENDER_DOCUMENT_TOKEN}
      />
      {showRelatedTask ? (
        <Select.Box
          name="task_id"
          placeholder="Select one"
          label="Related Task"
          options={taskOptions}
          required
        />
      ) : null}
      <UploadInput.Box
        name="documents"
        label="Documents"
        onUpload={onUpload}
        required
        descriptionField
        accept={ACCEPTED_DOCUMENT_MIME_TYPES}
        fileTypeDescription="document"
        minSize={1024}
      />
    </>
  );
};
