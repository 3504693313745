import { type IAction } from '@mortgagehippo/actionable';
import { Alert, HtmlRenderer, spacing } from '@mortgagehippo/ds';
import {
  type ITask,
  TaskContainer,
  taskHasCompleteAction,
  TaskSkeleton,
  TaskState,
  useTask,
} from '@mortgagehippo/tasks';
import { withErrorBoundary } from '@mortgagehippo/util';
import { trim } from 'lodash-es';
import { useCallback } from 'react';
import styled from 'styled-components';

import { GeneralError } from '$components/general-error';

import { analytics } from '../../../services/analytics';
import { type IApplicationApplicant } from '../use-application-file-data';
import {
  type IApplicationFileBlueprint,
  type IApplicationFileTask,
} from '../use-application-file-tasks';
import { ApplicationTaskHeader } from './application-task-header';
import { ApplicationTasksLockedContent } from './application-tasks-locked-content';

const TaskContent = styled('div')`
  max-width: 700px;
`;

const TaskStartDescription = styled('div')`
  margin-bottom: ${spacing(8)};
`;

const StyledAlert = styled(Alert)`
  margin: 0 auto ${spacing(5)};
  max-width: 100%;
`;

interface IApplicationTasksRouteContentProps {
  applicationFileId: string;
  task: IApplicationFileTask;
  taskApplicant: IApplicationApplicant;
  blueprint?: IApplicationFileBlueprint | null;
  showApplicantName?: boolean;
  onComplete: () => Promise<any>;
  onAction: (action: IAction<string, any, any>) => void;
  readOnly?: boolean;
  preventDownloads?: boolean;
}

const ApplicationTasksRouteContentWithBoundary = (props: IApplicationTasksRouteContentProps) => {
  const {
    applicationFileId,
    task,
    taskApplicant,
    blueprint,
    showApplicantName,
    onComplete,
    onAction,
    readOnly,
    preventDownloads,
  } = props;

  const [taskWithData, , taskWithDataLoading] = useTask<ITask>(task.id);

  const taskDescription = task.meta.description?.open || undefined;
  const alertMessage = (task.isAlerted && task.alertMessage) || undefined;

  const handleComplete = useCallback(async () => {
    const blueprintJson = blueprint?.json || {};

    analytics.event('tasks', 'complete', {
      label: task.key,
      applicationFileId,
      taskId: task.id,
      taskKey: task.key,
      taskTitle: task.meta.title,
    });

    if (taskHasCompleteAction(task, blueprintJson, 'application_submitted')) {
      analytics.event('application', 'submitted', {
        applicationFileId,
      });
    }

    return onComplete();
  }, [blueprint, task, applicationFileId, onComplete]);

  if (taskWithDataLoading || !taskWithData) {
    return (
      <TaskContent>
        <TaskSkeleton type={task.typeName} />
      </TaskContent>
    );
  }

  if (task.state === TaskState.locked) {
    return (
      <TaskContent>
        <ApplicationTasksLockedContent>
          <ApplicationTaskHeader
            applicationFileId={applicationFileId}
            task={task}
            taskApplicant={taskApplicant}
            showApplicantName={showApplicantName}
          />
        </ApplicationTasksLockedContent>
      </TaskContent>
    );
  }

  return (
    <TaskContent>
      <TaskContainer
        key={task.id}
        applicationFileId={applicationFileId}
        applicantId={`${task.primaryApplicantId}`}
        task={taskWithData}
        taskBlueprintType="borrower"
        taskApplicant={taskApplicant}
        onComplete={handleComplete}
        frame={false}
        readOnly={readOnly}
        formAutoComplete={false}
        showCompleteButton={false}
        onAction={onAction}
        preventDownloads={preventDownloads}
      >
        <ApplicationTaskHeader
          applicationFileId={applicationFileId}
          task={task}
          taskApplicant={taskApplicant}
          showApplicantName={showApplicantName}
        />
        {alertMessage ? (
          <StyledAlert type="warning" title="Re-open Message">
            {alertMessage}
          </StyledAlert>
        ) : null}
        {taskDescription ? (
          <TaskStartDescription>
            <HtmlRenderer value={trim(taskDescription)} />
          </TaskStartDescription>
        ) : null}
      </TaskContainer>
    </TaskContent>
  );
};

export const ApplicationTasksRouteContent = withErrorBoundary<IApplicationTasksRouteContentProps>(
  GeneralError
)(ApplicationTasksRouteContentWithBoundary);
