import {
  Card,
  fontSize,
  fontWeight,
  Format,
  type ISpacing,
  type ITableCols,
  type ITableProps,
  lineHeight,
  MediaBreakpoint,
  palette,
  paletteAlpha,
  Skeleton,
  spacing,
  Table,
} from '@mortgagehippo/ds';
import { type ReactElement, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { ActivityFeedTableTitle } from './activity-feed-table-title';
import { type IActivityFeedDictionary, type IActivityFeedEvent } from './types';
import { resolveFormattedValue } from './util';

const Time = styled.span`
  color: ${palette('neutral600')};
`;

const Actor = styled.span`
  font-weight: ${fontWeight('semibold')};
`;

const ActivityTable = styled(Table)<{ narrow?: boolean }>`
  ${(p) =>
    p.narrow &&
    css`
      padding-left: ${spacing(5)};
      padding-right: ${spacing(5)};

      td:first-child {
        padding-left: 0;
      }
      td:last-child {
        padding-right: 0;
      }
      tr:last-child {
        border-bottom: none;
      }

      ${MediaBreakpoint.TABLET} {
        padding: ${spacing(4)} ${spacing(3)};
        td:last-child {
          padding-right: ${spacing(3)};
        }
      }
    `}
` as <T>(props: ITableProps<T> & { narrow?: boolean }) => ReactElement;

const TableHeader = styled.div<{
  transparentBackground?: boolean;
  leftPadding?: ISpacing;
  narrow?: boolean;
}>`
  padding: ${spacing(3)} ${spacing(1)} ${spacing(3)} ${spacing(3)};
  top: 0;
  font-size: ${fontSize('sm')};
  line-height: ${lineHeight('sm')};
  border-bottom: 1px solid ${palette('neutral100')};

  ${(p) =>
    !p.narrow &&
    css`
      position: sticky;
      backdrop-filter: blur(4px);
    `}
  ${(p) =>
    p.leftPadding &&
    css`
      padding-left: ${spacing(p.leftPadding)};
    `}
  ${(p) =>
    !p.transparentBackground &&
    css`
      background-color: ${paletteAlpha('neutral50', 5)};
    `}
`;

interface IActivityFeedTableProps {
  dictionary: IActivityFeedDictionary;
  items: IActivityFeedEvent[];
  hideHeader?: boolean;
  loading?: boolean;
  narrow?: boolean;
}

export const ActivityFeedTable = (props: IActivityFeedTableProps) => {
  const { dictionary, hideHeader, items, loading, narrow } = props;

  const columns: ITableCols<IActivityFeedEvent> = useMemo(() => {
    if (narrow) {
      return [
        {
          key: 'date',
          render: ({ createdAt }) => {
            if (loading) {
              return <Skeleton.Line />;
            }

            return (
              <Time>
                <Format.Date value={createdAt} format="time" />
              </Time>
            );
          },
          width: '90px',
          verticalAlign: 'top',
        },
        {
          key: 'event',
          alignMobile: 'left',
          render: ({ actor, event }) => {
            if (loading) {
              return <Skeleton.Line />;
            }

            return (
              <>
                {actor ? (
                  <div>
                    <Actor>{resolveFormattedValue(actor, dictionary)}</Actor>
                  </div>
                ) : null}
                <div>{resolveFormattedValue(event, dictionary)}</div>
              </>
            );
          },
        },
      ];
    }
    return [
      {
        key: 'date',
        render: ({ createdAt }) => {
          if (loading) {
            return <Skeleton.Line />;
          }

          return (
            <Time>
              <Format.Date value={createdAt} format="time" />
            </Time>
          );
        },
        width: '90px',
      },
      {
        key: 'actor',
        render: ({ actor }) => {
          if (loading) {
            return <Skeleton.Line />;
          }

          if (!actor) {
            return null;
          }

          const resolvedActor = resolveFormattedValue(actor, dictionary);

          return <Actor>{resolvedActor}</Actor>;
        },
        width: '200px',
      },
      {
        key: 'event',
        render: ({ event }) => {
          if (loading) {
            return <Skeleton.Line />;
          }

          return resolveFormattedValue(event, dictionary);
        },
      },
    ];
  }, [dictionary, loading, narrow]);

  const date = items[0]?.createdAt;

  return (
    <Card compact raised={narrow ? 0 : undefined}>
      {!hideHeader && (
        <TableHeader
          transparentBackground={!!narrow}
          leftPadding={narrow ? 5 : undefined}
          narrow={narrow}
        >
          <ActivityFeedTableTitle date={date} loading={loading} />
        </TableHeader>
      )}
      <ActivityTable<IActivityFeedEvent>
        hideHeader
        size="sm"
        caption="Events"
        data={items}
        rowKey={(item) => item.id}
        cols={columns}
        zebra={!narrow}
        raised={0}
        emptyText="No activity history found"
        narrow={narrow}
      />
    </Card>
  );
};
